import React, { FC } from "react";
import { StyleProp, TextStyle } from "react-native";
import { COLOR, Text } from "@lookiero/aurora";

interface DescriptionProps {
  readonly children: string;
  readonly color?: COLOR;
  readonly testID?: string;
  readonly style?: StyleProp<TextStyle>;
}
const Description: FC<DescriptionProps> = ({ children, color = COLOR.TEXT_MEDIUM, testID, style: customStyle }) => (
  <Text color={color} level={3} style={customStyle} testID={testID}>
    {children}
  </Text>
);

export { Description };
