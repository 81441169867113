import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { colorBgBase, space2, space3 } = theme();

const style = StyleSheet.create({
  notification: {
    backgroundColor: colorBgBase,
    display: "flex",
    flexDirection: "row",
    gap: space2,
    justifyContent: "center",
    padding: space3,
  },
});

export { style };
