import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { colorBorderInputFocus, borderWidth1, borderRadius4, space4 } = theme();

const RADIUS = 22;

const style = StyleSheet.create({
  index: {
    alignItems: "center",
    borderColor: colorBorderInputFocus,
    borderRadius: borderRadius4,
    borderWidth: borderWidth1,
    height: RADIUS,
    justifyContent: "center",
    width: RADIUS,
  },
  step: {
    flexDirection: "row",
  },
  text: {
    flex: 1,
    marginLeft: space4,
  },
});

export { style };
