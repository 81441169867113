import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { colorBgSurface, space1, space6 } = theme();

const style = StyleSheet.create({
  column: {
    justifyContent: "center",
  },
  content: {
    flexGrow: 1,
    flexShrink: 0,
  },
  doubleHalf: {
    bottom: 0,
    right: -82,
  },
  notification: {
    backgroundColor: colorBgSurface,
  },
  rectangle: {
    left: -82,
    top: 0,
  },
  shape: {
    position: "absolute",
  },
  shapeContainer: {
    height: 196,
    overflow: "hidden",
  },
  text: {
    gap: space1,
    paddingVertical: space6,
  },
  title: {
    marginTop: 0,
  },
});

export { style };
