import React, { FC } from "react";
import { useI18nDate, useI18nMessage, useI18nTime } from "@lookiero/i18n-react";
import { I18nMessages, PREFIX } from "../../../i18n/i18n";
import { Description } from "../../atoms/description/Description";
import { Title } from "../../atoms/title/Title";
import { Body } from "../../layouts/body/Body";
import { Layout } from "../../layouts/layout/Layout";
import { style } from "./Expired.style";

interface ExpiredProps {
  readonly date: Date;
}
const Expired: FC<ExpiredProps> = ({ date }) => {
  const title = useI18nMessage({ prefix: PREFIX, id: I18nMessages.EXPIRATION_PAGE_TITLE });
  const expirationDate = useI18nDate({ value: date, dateStyle: "long" });
  const expirationTime = useI18nTime({ value: date, hour: "numeric", minute: "numeric" });
  const description = useI18nMessage({
    prefix: PREFIX,
    id: I18nMessages.EXPIRATION_PAGE_DESCRIPTION,
    values: { expirationDate, expirationTime },
  });

  return (
    <Layout>
      <Body style={{ column: style.column }}>
        <Title>{title}</Title>

        <Description>{description}</Description>
      </Body>
    </Layout>
  );
};

export { Expired };
