import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space1, space6 } = theme();

const style = StyleSheet.create({
  bodyRow: {
    flexGrow: 1,
    flexShrink: 0,
    paddingBottom: space6,
  },
  titleDescriptionContainer: {
    gap: space1,
    paddingBottom: space6,
  },
});

export { style };
