import React, { FC } from "react";
import { useI18nMessage } from "@lookiero/i18n-react";
import { Description } from "../../components/atoms/description/Description";
import { Title } from "../../components/atoms/title/Title";
import { Body } from "../../components/layouts/body/Body";
import { Layout } from "../../components/layouts/layout/Layout";
import { I18nMessages, PREFIX } from "../../i18n/i18n";
import { style } from "./NotFound.style";

const NotFound: FC = () => {
  const title = useI18nMessage({ prefix: PREFIX, id: I18nMessages.NOT_FOUND_PAGE_TITLE });
  const description = useI18nMessage({ prefix: PREFIX, id: I18nMessages.NOT_FOUND_PAGE_DESCRIPTION });

  return (
    <Layout>
      <Body style={{ column: style.column }}>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Body>
    </Layout>
  );
};

export { NotFound };
