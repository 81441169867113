import React, { FC } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { Icon, IconName, Text } from "@lookiero/aurora";
import { style } from "./Notification.style";

interface NotificationProps {
  readonly children: string;
  readonly icon?: IconName;
  readonly testID?: string;
  readonly style?: StyleProp<ViewStyle>;
}
const Notification: FC<NotificationProps> = ({ children, icon, testID, style: customStyle }) => (
  <View style={[style.notification, customStyle]} testID={testID}>
    {icon && <Icon name={icon} />}

    <Text level={2} detail>
      {children}
    </Text>
  </View>
);

export { Notification };
