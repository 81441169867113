import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space1 } = theme();

const style = StyleSheet.create({
  column: {
    gap: space1,
  },
});

export { style };
